import { apiGetAuth } from '@/utils/api'
import { notificationDanger } from '@/utils/notification'
import { API_SALES_ANALYTICS } from '@/utils/api-url'

const state = () => {
  return {
    top_category: {graph_top_sales_category: [], table_top_sales_category: []},
    top_plu: {graph_top_sales_plu: [], table_top_sales_plu: []},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_TOP_CATEGORY (state, payload) {
    state.top_category = payload
  },
  SET_TOP_PLU (state, payload) {
    state.top_plu = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
} 

const actions = {
  async getTopSalesCategory (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_ANALYTICS.TOP_SALES_CATEGORY, payload)
      context.commit('SET_TOP_CATEGORY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTTopSalesCategory (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_ANALYTICS.EXPORT_TOP_SALES_CATEGORY(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getTopSalesPlu (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_ANALYTICS.TOP_SALES_PLU, payload)
      context.commit('SET_TOP_PLU', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTTopSalesPlu (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_ANALYTICS.EXPORT_TOP_SALES_PLU(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  get_top_sales_category(state){
    return state.top_category
  },
  get_top_sales_plu(state){
    return state.top_plu
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
