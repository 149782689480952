import { createRouter, createWebHistory } from "vue-router"
import { isLogin } from '@/utils/auth'
import EmptyPageLayout from '@/components/layouts/EmptyPage'
import Login from '@/components/pages/Login'
import { getDefaultRedirect } from "@/utils/auth"
import { isAuthorizePage } from "../utils/auth"

const routes = [
  {
    path: '/',
    component: EmptyPageLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/forgot-password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/Forgot'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/reset-password',
        name: 'Reset',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/Forgot'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/reset-password',
        name: 'MobileResetPassword',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/ResetPassword'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/email-verification',
        name: 'EmailVerification',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/EmailVerification'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/failed-page',
        name: 'FailedPage',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/FailedPage'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/success-page',
        name: 'SuccessPage',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/SuccessPage'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/Term'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/Privacy'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      }
    ]
  },
  {
    path: '/admin',
    component: EmptyPageLayout,
    redirect: '/admin/main-page',
    children: [
      { 
        path: 'main-page',
        name: 'MainPage',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/pages/dashboard/main-page')
      },
      {
        path: 'aphrodite/order-management/:id/preview',
        name: 'OrderManagementPreview',
        props: true,
        component: () => import(/* webpackChunkName: "order-management-preview" */ '@/components/pages/dashboard/aphrodite/order-management/preview')
      },
      {
        path: 'dashboard',
        name: 'Main Page Dashboard',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
        props: { type: 'ssosis' },
        redirect: getDefaultRedirect('ssosis'),
        children: [
          {
            path: 'role-management',
            name: 'RoleManagement',
            component: () => import(/* webpackChunkName: "role-management" */ '@/components/pages/dashboard/main-page/role')
          },
          {
            path: 'role-management/create',
            name: 'RoleManagementCreate',
            component: () => import(/* webpackChunkName: "role-management-create" */ '@/components/pages/dashboard/main-page/role/create')
          },
          {
            path: 'role-management/:id/detail',
            name: 'RoleManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "role-management-detail" */ '@/components/pages/dashboard/main-page/role/detail')
          },
          {
            path: 'role-management/:id/edit',
            name: 'RoleManagementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "role-management-edit" */ '@/components/pages/dashboard/main-page/role/edit')
          },
          {
            path: 'user-management',
            name: 'UserManagement',
            component: () => import(/* webpackChunkName: "user-management" */ '@/components/pages/dashboard/main-page/user')
          },
          {
            path: 'user-management/create',
            name: 'UserManagementCreate',
            component: () => import(/* webpackChunkName: "user-management-create" */ '@/components/pages/dashboard/main-page/user/create')
          },
          {
            path: 'user-management/:id/detail',
            name: 'UserManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "user-management-detail" */ '@/components/pages/dashboard/main-page/user/detail')
          },
          {
            path: 'user-management/:id/edit',
            name: 'UserManagementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "user-management-edit" */ '@/components/pages/dashboard/main-page/user/edit')
          },
          {
            path: 'role/inactive',
            name: 'RoleInactive',
            component: () => import(/* webpackChunkName: "inactive" */ '@/components/pages/dashboard/aphrodite/Inactive')
          },
          {
            path: 'unauthorized',
            name: 'Unauthorized',
            component: () => import(/* webpackChunkName: "unauthorized" */ '@/components/pages/Unauthorized')
          }
        ]
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
        props: { type: 'other' },
        redirect: '/admin/settings/profile',
        children: [
          {
            path: 'profile',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/setting/profile')
          },
          {
            path: 'change-password',
            name: 'ChangePassword',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/setting/change-password')
          }
        ]
      },
      {
        path: 'aphrodite',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
        props: { type: 'cms' },
        redirect: getDefaultRedirect('cms'),
        children: [
          {
            path: 'store-management',
            name: 'StoreManagement',
            component: () => import(/* webpackChunkName: "store-management" */ '@/components/pages/dashboard/aphrodite/store-management/index')
          },
          {
            path: 'store-management/bulk-edit',
            name: 'StoreManagementBulkEdit',
            props: true,
            component: () => import(/* webpackChunkName: "store-management-bulk-edit" */ '@/components/pages/dashboard/aphrodite/store-management/bulk-edit')
          },
          {
            path: 'store-management/:id/detail',
            name: 'StoreManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "store-management-detail" */ '@/components/pages/dashboard/aphrodite/store-management/detail')
          },
          {
            path: 'store-management/:id/edit',
            name: 'StoreManagementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "store-management-edit" */ '@/components/pages/dashboard/aphrodite/store-management/edit')
          },
          {
            path: 'store-management/store-product/:id/detail',
            name: 'StoreProductDetail',
            props: true,
            component: () => import(/* webpackChunkName: "store-product-detail" */ '@/components/pages/dashboard/aphrodite/store-management/detail/store-product')
          },
          {
            path: 'store-management/store-product/:id/edit',
            name: 'StoreProductEdit',
            props: true,
            component: () => import(/* webpackChunkName: "store-product-edit" */ '@/components/pages/dashboard/aphrodite/store-management/edit/store-product')
          },
          {
            path: 'member-management',
            name: 'MemberManagement',
            component: () => import(/* webpackChunkName: "member-management" */ '@/components/pages/dashboard/aphrodite/member-management')
          },
          {
            path: 'promo-management',
            name: 'PromoManagement',
            component: () => import(/* webpackChunkName: "promo-management" */ '@/components/pages/dashboard/aphrodite/promo-management')
          },
          {
            path: 'promo-management/import',
            name: 'PromoManagementImport',
            component: () => import(/* webpackChunkName: "promo-management-import" */ '@/components/pages/dashboard/aphrodite/promo-management/upload')
          },
          {
            path: 'promo-management/create',
            name: 'PromoManagementCreate',
            component: () => import(/* webpackChunkName: "promo-management-create" */ '@/components/pages/dashboard/aphrodite/promo-management/create')
          },
          {
            path: 'promo-management/:id/detail',
            name: 'PromoManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "promo-management-detail" */ '@/components/pages/dashboard/aphrodite/promo-management/detail')
          },
          {
            path: 'promo-management/:id/Edit',
            name: 'PromoManagementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "promo-management-edit" */ '@/components/pages/dashboard/aphrodite/promo-management/edit')
          },
          {
            path: 'free-gift-promotion',
            name: 'FreeGiftPromotion',
            component: () => import(/* webpackChunkName: "free-gift-promotion" */ '@/components/pages/dashboard/aphrodite/free-gift-promotion')
          },
          {
            path: 'free-gift-promotion/:id/detail',
            name: 'FreeGiftPromotionDetail',
            props: true,
            component: () => import(/* webpackChunkName: "free-gift-promotion" */ '@/components/pages/dashboard/aphrodite/free-gift-promotion/detail')
          },
          {
            path: 'free-gift-promotion/create',
            name: 'FreeGiftPromotionCreate',
            component: () => import(/* webpackChunkName: "promo-management-edit" */ '@/components/pages/dashboard/aphrodite/free-gift-promotion/create')
          },
          {
            path: 'free-gift-promotion/:id/edit',
            name: 'FreeGiftPromotionEdit',
            props: true,
            component: () => import(/* webpackChunkName: "free-gift-promotion" */ '@/components/pages/dashboard/aphrodite/free-gift-promotion/edit')
          },
          {
            path: 'planogram-management',
            name: 'PlanogramManagement',
            component: () => import(/* webpackChunkName: "planogram-management" */ '@/components/pages/dashboard/aphrodite/planogram-management')
          },
          {
            path: 'planogram-management/create',
            name: 'PlanogramManagementCreate',
            component: () => import(/* webpackChunkName: "planogram-management-create" */ '@/components/pages/dashboard/aphrodite/planogram-management/create')
          },
          {
            path: 'planogram-management/:id/detail',
            name: 'PlanogramManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "planogram-management-detail" */ '@/components/pages/dashboard/aphrodite/planogram-management/detail')
          },
          {
            path: 'planogram-management/:id/edit',
            name: 'PlanogramManagementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "planogram-management-edit" */ '@/components/pages/dashboard/aphrodite/planogram-management/edit')
          },
          {
            path: '3pl-setting',
            name: '3PLSetting',
            component: () => import(/* webpackChunkName: "3pl-setting" */ '@/components/pages/dashboard/aphrodite/3pl-setting')
          },
          {
            path: '3pl-setting/:id/detail',
            name: '3PLSettingDetail',
            props: true,
            component: () => import(/* webpackChunkName: "3pl-setting-detail" */ '@/components/pages/dashboard/aphrodite/3pl-setting/detail')
          },
          {
            path: '3pl-setting/:id/edit',
            name: '3PLSettingEdit',
            props: true,
            component: () => import(/* webpackChunkName: "3pl-setting-edit" */ '@/components/pages/dashboard/aphrodite/3pl-setting/edit')
          },
          {
            path: 'member-management/:id/edit',
            name: 'MemberManagementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "offers-edit" */ '@/components/pages/dashboard/aphrodite/member-management/edit-detail')
          },
          {
            path: 'member-management/:id/detail',
            name: 'MemberManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "offers-edit" */ '@/components/pages/dashboard/aphrodite/member-management/edit-detail')
          },
          {
            path: 'member-management/info-address/:id/edit',
            name: 'MemberManagementAddressEdit',
            props: true,
            component: () => import(/* webpackChunkName: "member-address-edit" */ '@/components/pages/dashboard/aphrodite/member-management/info-address/edit-detail')
          },
          {
            path: 'member-management/info-address/:id/detail',
            name: 'MemberManagementAddressDetail',
            props: true,
            component: () => import(/* webpackChunkName: "member-address-detail" */ '@/components/pages/dashboard/aphrodite/member-management/info-address/edit-detail')
          },
          {
            path: 'member-management/coupon-history/:id/detail',
            name: 'MemberManagementCouponDetail',
            props: true,
            component: () => import(/* webpackChunkName: "consumer-coupon-history" */ '@/components/pages/dashboard/aphrodite/consumer/consumer/coupon-history/detail')
          },
          {
            path: 'order-management',
            name: 'OrderManagement',
            component: () => import(/* webpackChunkName: "order-management" */ '@/components/pages/dashboard/aphrodite/order-management')
          },
          {
            path: 'order-management/:id/detail',
            name: 'OrderManagementDetail',
            props: true,
            component: () => import(/* webpackChunkName: "order-management-detail" */ '@/components/pages/dashboard/aphrodite/order-management/detail')
          },
          {
            path: 'dashboard',
            name: 'Dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/components/pages/dashboard/aphrodite/dashboard')
          },
          {
            path: 'method-of-payment/bulk-voucher',
            name: 'BulkVoucher',
            component: () => import(/* webpackChunkName: "coupon-setting" */ '@/components/pages/dashboard/aphrodite/method-of-payment/bulk-voucher')
          },
          {
            path: 'method-of-payment/credit-limit',
            name: 'CreditLimit',
            component: () => import(/* webpackChunkName: "coupon-setting" */ '@/components/pages/dashboard/aphrodite/method-of-payment/credit-limit')
          },
          {
            path: 'digital-coupon/coupon-setting',
            name: 'CouponSetting',
            component: () => import(/* webpackChunkName: "coupon-setting" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-setting')
          },
          {
            path: 'digital-coupon/coupon-setting/:id/detail',
            name: 'CouponSettingDetail',
            props: true,
            component: () => import(/* webpackChunkName: "coupon-setting-detail" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-setting/detail')
          },
          {
            path: 'digital-coupon/coupon-setting/:id/edit',
            name: 'CouponSettingEdit',
            props: true,
            component: () => import(/* webpackChunkName: "coupon-setting-edit" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-setting/edit')
          },
          {
            path: 'digital-coupon/coupon-setting/:id/duplicate',
            name: 'CouponSettingDuplicate',
            props: true,
            component: () => import(/* webpackChunkName: "coupon-setting-duplicate" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-setting/duplicate')
          },
          {
            path: 'digital-coupon/coupon-setting/create',
            name: 'CouponSettingCreate',
            component: () => import(/* webpackChunkName: "coupon-setting-create" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-setting/create')
          },
          {
            path: 'digital-coupon/coupon-log',
            name: 'CouponLog',
            component: () => import(/* webpackChunkName: "coupon-log" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-log')
          },
          {
            path: 'digital-coupon/coupon-log/:id/detail',
            name: 'CouponLogDetail',
            props: true,
            component: () => import(/* webpackChunkName: "coupon-log-detail" */ '@/components/pages/dashboard/aphrodite/digital-coupon/coupon-log/detail')
          },
          {
            path: 'digital-coupon/raffle',
            name: 'Raffle',
            component: () => import(/* webpackChunkName: "raffle" */ '@/components/pages/dashboard/aphrodite/digital-coupon/raffle')
          },
          {
            path: 'digital-coupon/raffle/:id/detail',
            name: 'RaffleDetail',
            props: true,
            component: () => import(/* webpackChunkName: "raffle-detail" */ '@/components/pages/dashboard/aphrodite/digital-coupon/raffle/detail')
          },
          {
            path: 'digital-coupon/raffle/create',
            name: 'RaffleCreate',
            component: () => import(/* webpackChunkName: "raffle-detail" */ '@/components/pages/dashboard/aphrodite/digital-coupon/raffle/create')
          },
          {
            path: 'download-list',
            name: 'DownloadList',
            component: () => import(/* webpackChunkName: "download-list" */ '@/components/pages/dashboard/aphrodite/download-list')
          },
          {
            path: 'campaign/offers',
            name: 'Offers',
            component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/dashboard/aphrodite/campaign/offers')
          },
          {
            path: 'campaign/offers/create',
            name: 'OffersCreate',
            component: () => import(/* webpackChunkName: "offers-create" */ '@/components/pages/dashboard/aphrodite/campaign/offers/create')
          },
          {
            path: 'campaign/offers/:id/detail',
            name: 'OffersDetail',
            props: true,
            component: () => import(/* webpackChunkName: "offers-detail" */ '@/components/pages/dashboard/aphrodite/campaign/offers/detail')
          },
          {
            path: 'campaign/offers/:id/edit',
            name: 'OffersEdit',
            props: true,
            component: () => import(/* webpackChunkName: "offers-edit" */ '@/components/pages/dashboard/aphrodite/campaign/offers/edit')
          },
          {
            path: 'campaign/offers/:id/duplicate',
            name: 'OffersDuplicate',
            props: true,
            component: () => import(/* webpackChunkName: "offers-duplicate" */ '@/components/pages/dashboard/aphrodite/campaign/offers/duplicate')
          },
          {
            path: 'campaign/banners',
            name: 'Banners',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/campaign/banners')
          },
          {
            path: 'campaign/banners/create',
            name: 'BannersCreate',
            props: true,
            component: () => import(/* webpackChunkName: "banners-create" */ '@/components/pages/dashboard/aphrodite/campaign/banners/create')
          },
          {
            path: 'campaign/banners/:id/detail',
            name: 'BannersDetail',
            props: true,
            component: () => import(/* webpackChunkName: "banners-detail" */ '@/components/pages/dashboard/aphrodite/campaign/banners/detail')
          },
          {
            path: 'campaign/banners/:id/edit',
            name: 'BannersEdit',
            props: true,
            component: () => import(/* webpackChunkName: "banners-edit" */ '@/components/pages/dashboard/aphrodite/campaign/banners/edit')
          },
          {
            path: 'campaign/banners/:id/duplicate',
            name: 'BannersDuplicate',
            props: true,
            component: () => import(/* webpackChunkName: "banners-duplicate" */ '@/components/pages/dashboard/aphrodite/campaign/banners/duplicate')
          },
          {
            path: 'campaign/campaigns',
            name: 'Campaigns',
            component: () => import(/* webpackChunkName: "campaigns" */ '@/components/pages/dashboard/aphrodite/campaign/campaigns')
          },
          {
            path: 'campaign/campaigns/create',
            name: 'CampaignsCreate',
            component: () => import(/* webpackChunkName: "campaigns-create" */ '@/components/pages/dashboard/aphrodite/campaign/campaigns/create')
          },
          {
            path: 'campaign/campaigns/:id/detail',
            name: 'CampaignsDetail',
            props: true,
            component: () => import(/* webpackChunkName: "campaigns-detail" */ '@/components/pages/dashboard/aphrodite/campaign/campaigns/detail')
          },
          {
            path: 'campaign/campaigns/:id/edit',
            name: 'CampaignsEdit',
            props: true,
            component: () => import(/* webpackChunkName: "campaigns-edit" */ '@/components/pages/dashboard/aphrodite/campaign/campaigns/edit')
          },
          {
            path: 'campaign/push-messages',
            name: 'PushMessages',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/campaign/push-messages')
          },
          {
            path: 'campaign/push-messages/create',
            name: 'PushMessagesCreate',
            props: true,
            component: () => import(/* webpackChunkName: "push-messages-create" */ '@/components/pages/dashboard/aphrodite/campaign/push-messages/create')
          },
          {
            path: 'campaign/push-messages/:id/detail',
            name: 'PushMessagesDetail',
            props: true,
            component: () => import(/* webpackChunkName: "push-messages-detail" */ '@/components/pages/dashboard/aphrodite/campaign/push-messages/detail')
          },
          {
            path: 'campaign/push-messages/:id/edit',
            name: 'PushMessagesEdit',
            props: true,
            component: () => import(/* webpackChunkName: "push-messages-edit" */ '@/components/pages/dashboard/aphrodite/campaign/push-messages/edit')
          },
          {
            path: 'campaign/push-messages/:id/duplicate',
            name: 'PushMessagesDuplicate',
            props: true,
            component: () => import(/* webpackChunkName: "push-messages-duplicate" */ '@/components/pages/dashboard/aphrodite/campaign/push-messages/duplicate')
          },
          {
            path: 'campaign/approval-messages',
            name: 'ApprovalMessages',
            component: () => import(/* webpackChunkName: "approval-messages" */ '@/components/pages/dashboard/aphrodite/campaign/approval-messages')
          },
          {
            path: 'campaign/approval-messages/edit',
            name: 'ApprovalMessagesEdit',
            props: true,
            component: () => import(/* webpackChunkName: "approval-messages-edit" */ '@/components/pages/dashboard/aphrodite/campaign/approval-messages/edit')
          },
          {
            path: 'campaign/notification-offer',
            name: 'NotifOffer',
            component: () => import(/* webpackChunkName: "notification-offer" */ '@/components/pages/dashboard/aphrodite/campaign/notification-offer')
          },
          {
            path: 'campaign/notification-offer/edit',
            name: 'NotifOfferEdit',
            props: true,
            component: () => import(/* webpackChunkName: "notification-offer-edit" */ '@/components/pages/dashboard/aphrodite/campaign/notification-offer/edit')
          },
          {
            path: 'topup-bills/order-management-digital-product',
            name: 'OrderManagementDigitalProduct',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/order-management-digital-product')
          },
          {
            path: 'topup-bills/order-management-digital-product/:id/detail',
            name: 'OrderManagementDigitalProductDetail',
            props: true,
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/order-management-digital-product/detail')
          },
          {
            path: 'topup-bills/cta-setting',
            name: 'CTASetting',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/banner-digital-product/cta-setting')
          },
          {
            path: 'topup-bills/banner-digital-product',
            name: 'BannerDigitalProduct',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/banner-digital-product')
          },
          {
            path: 'topup-bills/banner-digital-product/create',
            name: 'BannerDigitalProductCreate',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/banner-digital-product/create')
          },
          {
            path: 'topup-bills/banner-digital-product/:id/detail',
            name: 'BannerDigitalProductDetail',
            props: true,
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/banner-digital-product/detail')
          },
          {
            path: 'topup-bills/banner-digital-product/:id/edit',
            name: 'BannerDigitalProductEdit',
            props: true,
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/topup-bills/banner-digital-product/edit')
          },
          {
            path: 'digital-stamp/stamp',
            name: 'Stamps',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/digital-stamp/stamp')
          },
          {
            path: 'digital-stamp/stamp/create',
            name: 'StampsCreate',
            component: () => import(/* webpackChunkName: "stamps-create" */ '@/components/pages/dashboard/aphrodite/digital-stamp/stamp/create')
          },
          {
            path: 'digital-stamp/stamp/:id/detail',
            name: 'StampsDetail',
            props: true,
            component: () => import(/* webpackChunkName: "stamps-detail" */ '@/components/pages/dashboard/aphrodite/digital-stamp/stamp/detail')
          },
          {
            path: 'digital-stamp/stamp/:id/edit',
            name: 'StampsEdit',
            props: true,
            component: () => import(/* webpackChunkName: "stamps-edit" */ '@/components/pages/dashboard/aphrodite/digital-stamp/stamp/edit')
          },
          {
            path: 'digital-stamp/reward',
            name: 'Rewards',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/digital-stamp/reward')
          },
          {
            path: 'digital-stamp/reward/create',
            name: 'RewardsCreate',
            component: () => import(/* webpackChunkName: "stamps-create" */ '@/components/pages/dashboard/aphrodite/digital-stamp/reward/create')
          },
          {
            path: 'digital-stamp/reward/:id/detail',
            name: 'RewardsDetail',
            props: true,
            component: () => import(/* webpackChunkName: "stamps-detail" */ '@/components/pages/dashboard/aphrodite/digital-stamp/reward/detail')
          },
          {
            path: 'digital-stamp/reward/:id/edit',
            name: 'RewardsEdit',
            props: true,
            component: () => import(/* webpackChunkName: "stamps-edit" */ '@/components/pages/dashboard/aphrodite/digital-stamp/reward/edit')
          },
          {
            path: 'digital-stamp/report',
            name: 'Reports',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/digital-stamp/report')
          },
          {
            path: 'digital-stamp/coupon/:id/detail',
            name: 'ReportsDetail',
            props: true,
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/aphrodite/digital-stamp/report/coupon/detail')
          },
          {
            path: 'digital-stamp/how-to-redeem',
            name: 'HowtoRedeem',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/digital-stamp/how-to-redeem')
          },
          {
            path: 'digital-stamp/how-to-redeem/edit',
            name: 'HowtoRedeemEdit',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/digital-stamp/how-to-redeem/edit')

          },
          {
            path: 'polling-survey/form',
            name: 'Form',
            component: () => import(/* webpackChunkName: "form" */ '@/components/pages/dashboard/aphrodite/polling-survey/form')
          },
          {
            path: 'polling-survey/form/create',
            name: 'FormCreate',
            component: () => import(/* webpackChunkName: "form-create" */ '@/components/pages/dashboard/aphrodite/polling-survey/form/create')
          },
          {
            path: 'polling-survey/form/:id/detail',
            name: 'FormDetail',
            props: true,
            component: () => import(/* webpackChunkName: "form-detail" */ '@/components/pages/dashboard/aphrodite/polling-survey/form/detail')
          },
          {
            path: 'polling-survey/form/:id/edit',
            name: 'FormEdit',
            props: true,
            component: () => import(/* webpackChunkName: "form-edit" */ '@/components/pages/dashboard/aphrodite/polling-survey/form/edit')
          },
          {
            path: 'polling-survey/response',
            name: 'Response',
            component: () => import(/* webpackChunkName: "response" */ '@/components/pages/dashboard/aphrodite/polling-survey/response')
          },
          {
            path: 'polling-survey/response/:id/detail',
            name: 'ResponseDetail',
            props: true,
            component: () => import(/* webpackChunkName: "response-detail" */ '@/components/pages/dashboard/aphrodite/polling-survey/response/detail')
          },
          {
            path: 'consumer/consumer',
            name: 'Consumer',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/consumer')
          },
          {
            path: 'consumer/consumer/:id/detail',
            name: 'ConsumerDetail',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/consumer/edit-detail')
          },
          {
            path: 'consumer/consumer/:id/edit',
            name: 'ConsumerEdit',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/consumer/edit-detail')
          },
          {
            path: 'consumer/consumer/coupon-history/:id/detail',
            name: 'ConsumerCouponDetail',
            props: true,
            component: () => import(/* webpackChunkName: "consumer-coupon-history" */ '@/components/pages/dashboard/aphrodite/consumer/consumer/coupon-history/detail')
          },
          {
            path: 'consumer/help-center',
            name: 'HelpCenter',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/help_center')
          },
          {
            path: 'consumer/help-center/:id/detail',
            name: 'HelpCenterDetail',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/help_center/detail')
          },
          {
            path: 'consumer/help-center/:id/edit',
            name: 'HelpCenterEdit',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/help_center/edit')
          },
          {
            path: 'consumer/help-center/create',
            name: 'HelpCenterCreate',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/help_center/create')
          },
          {
            path: 'consumer/faq',
            name: 'Faq',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/faq')
          },
          {
            path: 'consumer/faq/create',
            name: 'FaqCreate',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/faq/create')
          },
          {
            path: 'consumer/faq/:id/detail',
            name: 'FaqDetail',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/faq/detail')
          },
          {
            path: 'consumer/faq/:id/edit',
            name: 'FaqEdit',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/faq/edit')
          },
          {
            path: 'consumer/rating',
            name: 'Rating',
            component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/dashboard/aphrodite/consumer/rating')
          },
          {
            path: 'consumer/rating/:id/detail',
            name: 'RatingDetail',
            props: true,
            component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/dashboard/aphrodite/consumer/rating/detail')
          },
          {
            path: 'consumer/chatbot',
            name: 'ChatBot',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/chatbot')
          },
          {
            path: 'consumer/chatbot/edit',
            name: 'ChatBotEdit',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/chatbot/edit')
          },
          {
            path: 'consumer/chatlive',
            name: 'ChatLive',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/consumer/chatlive')
          },
          {
            path: 'consumer/chatlive/:id/detail',
            name: 'ChatLiveDetail',
            props: true,
            component: () => import(/* webpackChunkName: "chatlive" */ '@/components/pages/dashboard/aphrodite/consumer/chatlive/detail')
          },
          {
            path: 'master-data/custom-consumer-tag',
            name: 'CustomTag',
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/consumer-tag')
          },
          {
            path: 'master-data/custom-consumer-tag/create',
            name: 'CustomTagCreate',
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/consumer-tag/create')
          },
          {
            path: 'master-data/custom-consumer-tag/:id/detail',
            name: 'CustomTagDetail',
            props: true,
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/consumer-tag/detail')
          },
          {
            path: 'master-data/custom-consumer-tag/:id/edit',
            name: 'CustomTagEdit',
            props: true,
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/consumer-tag/edit')
          },
          {
            path: 'master-data/group-store',
            name: 'Groupstore',
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/group-store')
          },
          {
            path: 'master-data/group-store/create',
            name: 'GroupstoreCreate',
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/group-store/create')
          },
          {
            path: 'master-data/group-store/:id/detail',
            name: 'GroupstoreDetail',
            props: true,
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/group-store/detail')
          },
          {
            path: 'master-data/group-store/:id/edit',
            name: 'GroupstoreEdit',
            props: true,
            component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/dashboard/aphrodite/master-data/group-store/edit')
          },
          {
            path: 'master-data/store',
            name: 'Store',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/master-data/store')
          },
          {
            path: 'master-data/product',
            name: 'Product',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/master-data/product')
          },
          {
            path: 'master-data/privacy-policy',
            name: 'PrivacyPolicy',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/master-data/privacy-policy')
          },
          {
            path: 'master-data/privacy-policy/edit',
            name: 'PrivacyPolicyEdit',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/master-data/privacy-policy/edit')
          },
          {
            path: 'master-data/setting-app',
            name: 'SettingApp',
            component: () => import(/* webpackChunkName: "setting-app" */ '@/components/pages/dashboard/aphrodite/master-data/setting-app')
          },
          {
            path: 'master-data/setting-app/edit',
            name: 'SettingAppEdit',
            component: () => import(/* webpackChunkName: "setting-app-edit" */ '@/components/pages/dashboard/aphrodite/master-data/setting-app/edit')
          },
          {
            path: 'master-data/term-condition',
            name: 'TermCondition',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/master-data/term-condition')
          },
          {
            path: 'master-data/term-condition/edit',
            name: 'TermConditionEdit',
            component: () => import(/* webpackChunkName: "store" */ '@/components/pages/dashboard/aphrodite/master-data/term-condition/edit')
          },
          {
            path: 'master-data/ticket-category-management',
            name: 'TicketCategoryManagement',
            component: () => import(/* webpackChunkName: "ticket-category-management" */ '@/components/pages/dashboard/aphrodite/master-data/ticket-category-management')
          },
          {
            path: 'master-data/master-mapping-email',
            name: 'MasterMappingEmail',
            component: () => import(/* webpackChunkName: "master-mapping-email" */ '@/components/pages/dashboard/aphrodite/master-data/master-mapping-email')
          },
          {
            path: 'user-management/log-history',
            name: 'LogHistory',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/user-management/log-history')
          },
          {
            path: 'user-management/log-history/:id/detail',
            name: 'LogHistoryDetail',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/user-management/log-history/detail')
          },
          {
            path: 'user-management/user',
            name: 'User',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/user-management/user')
          },
          {
            path: 'user-management/user/create',
            name: 'UserCreate',
            component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/dashboard/aphrodite/user-management/user/create')
          },
          {
            path: 'user-management/user/:id/detail',
            name: 'UserDetail',
            props: true,
            component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/dashboard/aphrodite/user-management/user/detail')
          },
          {
            path: 'user-management/user/:id/edit',
            name: 'UserEdit',
            props: true,
            component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/dashboard/aphrodite/user-management/user/edit')
          },
          {
            path: 'user-management/role',
            name: 'Role',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/user-management/role')
          },
          {
            path: 'user-management/role/create',
            name: 'RoleCreate',
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/user-management/role/create')
          },
          {
            path: 'user-management/role/:id/detail',
            name: 'RoleDetail',
            props: true,
            component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/dashboard/aphrodite/user-management/role/detail')
          },
          {
            path: 'user-management/role/:id/edit',
            name: 'RoleEdit',
            props: true,
            component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/dashboard/aphrodite/user-management/role/edit')
          },
          {
            path: 'general-setting/',
            name: 'GeneralSetting',
            component: () => import(/* webpackChunkName: "setting-app" */ '@/components/pages/dashboard/aphrodite/general-setting')
          },
          {
            path: 'general-setting/edit',
            name: 'GeneralSettingEdit',
            component: () => import(/* webpackChunkName: "general-setting" */ '@/components/pages/dashboard/aphrodite/general-setting/edit')
          },
          {
            path: 'app-version/',
            name: 'AppVersion',
            component: () => import(/* webpackChunkName: "setting-app" */ '@/components/pages/dashboard/aphrodite/app-version')
          },
          {
            path: 'app-version/edit',
            name: 'AppVersionEdit',
            component: () => import(/* webpackChunkName: "app-version" */ '@/components/pages/dashboard/aphrodite/app-version/edit')
          },
          {
            path: 'associate-loyalty-program',
            name: 'AssociateLoyaltyProgram',
            props: true,
            component: () => import(/* webpackChunkName: "associate-loyalty-program" */ '@/components/pages/dashboard/aphrodite/associate-loyalty-program')
          },
          {
            path: 'associate-loyalty-program/bulk',
            name: 'AssociateLoyaltyProgramBulk',
            props: true,
            component: () => import(/* webpackChunkName: "associate-loyalty-program" */ '@/components/pages/dashboard/aphrodite/associate-loyalty-program/upload')
          },
          {
            path: 'associate-loyalty-program/create',
            name: 'AssociateLoyaltyProgramCreate',
            props: true,
            component: () => import(/* webpackChunkName: "associate-loyalty-program" */ '@/components/pages/dashboard/aphrodite/associate-loyalty-program/create')
          },
          {
            path: 'associate-loyalty-program/:employeeNo/detail',
            name: 'AssociateLoyaltyProgramDetail',
            props: true,
            component: () => import(/* webpackChunkName: "associate-loyalty-program" */ '@/components/pages/dashboard/aphrodite/associate-loyalty-program/detail')
          },
          {
            path: 'associate-loyalty-program/:employeeNo/edit',
            name: 'AssociateLoyaltyProgramEdit',
            props: true,
            component: () => import(/* webpackChunkName: "associate-loyalty-program" */ '@/components/pages/dashboard/aphrodite/associate-loyalty-program/edit')
          }
        ]
      },
      {
        path: 'orion',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
        props: { type: 'orion' },
        redirect: getDefaultRedirect('orion'),
        children: [
          {
            path: 'category-management/parent-category',
            name: 'ParentCategory',
            component: () => import('@/components/pages/dashboard/orion/category-management/parent')
          },
          {
            path: 'category-management/parent-category/create',
            name: 'ParentCategoryCreate',
            component: () => import('@/components/pages/dashboard/orion/category-management/parent/create')
          },
          {
            path: 'category-management/parent-category/:id/detail',
            name: 'ParentCategoryDetail',
            props: true,
            component: () => import('@/components/pages/dashboard/orion/category-management/parent/detail')
          },
          {
            path: 'category-management/parent-category/:id/edit',
            name: 'ParentCategoryEdit',
            props: true,
            component: () => import('@/components/pages/dashboard/orion/category-management/parent/edit')
          },
          {
            path: 'category-management/sub-category',
            name: 'SubCategory',
            component: () => import('@/components/pages/dashboard/orion/category-management/sub')
          },
          {
            path: 'category-management/sub-category/:id/detail',
            name: 'SubCategoryDetail',
            props: true,
            component: () => import('@/components/pages/dashboard/orion/category-management/sub/detail')
          },
          {
            path: 'category-management/sub-category/:id/edit',
            name: 'SubCategoryEdit',
            props: true,
            component: () => import('@/components/pages/dashboard/orion/category-management/sub/edit')
          },
          {
            path: 'product-management/product-management',
            name: 'ProductManagement',
            component: () => import(/* webpackChunkName: "product-management" */ '@/components/pages/dashboard/orion/product-management/product-management')
          },
          {
            path: 'product-management/product-management/:id/edit',
            name: 'ProductManagementEdit',
            component: () => import(/* webpackChunkName: "product-management" */ '@/components/pages/dashboard/orion/product-management/product-management/edit'),
            props: true
          },
          {
            path: 'product-management/product-management/:id/detail',
            name: 'ProductManagementDetail',
            component: () => import(/* webpackChunkName: "product-management" */ '@/components/pages/dashboard/orion/product-management/product-management/detail'),
            props: true
          },
          {
            path: 'product-management/product-management/:id/view-store',
            name: 'ProductManagementViewStore',
            component: () => import(/* webpackChunkName: "product-management" */ '@/components/pages/dashboard/orion/product-management/product-management/view-store'),
            props: true
          },
          {
            path: 'product-management/product-management/import',
            name: 'ProductManagementImport',
            component: () => import(/* webpackChunkName: "product-management" */ '@/components/pages/dashboard/orion/product-management/product-management/import'),
            props: true
          },
          {
            path: 'product-management/product-management/preview',
            name: 'ProductManagementPreview',
            component: () => import(/* webpackChunkName: "product-management" */ '@/components/pages/dashboard/orion/product-management/product-management/preview'),
            props: true
          },
          {
            path: 'product-management/digital-product-management',
            name: 'DigitalProductManagement',
            component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/dashboard/orion/product-management/digital-product-management')
          },
          {
            path: 'product-management/max-purchase-limitation',
            name: 'MaxPurchaseLimitation',
            component: () => import(/* webpackChunkName: "max-purchase-limitation" */ '@/components/pages/dashboard/orion/product-management/max-purchase-limitation')
          },
          {
            path: 'product-management/max-purchase-limitation/:id/detail',
            name: 'MaxPurchaseLimitationDetail',
            props: true,
            component: () => import(/* webpackChunkName: "max-purchase-limitation" */ '@/components/pages/dashboard/orion/product-management/max-purchase-limitation/detail')
          },
          {
            path: 'product-management/max-purchase-limitation/create',
            name: 'MaxPurchaseLimitationCreate',
            component: () => import(/* webpackChunkName: "max-purchase-limitation" */ '@/components/pages/dashboard/orion/product-management/max-purchase-limitation/create')
          },
          {
            path: 'product-management/max-purchase-limitation/:id/edit',
            name: 'MaxPurchaseLimitationEdit',
            props: true,
            component: () => import(/* webpackChunkName: "max-purchase-limitation" */ '@/components/pages/dashboard/orion/product-management/max-purchase-limitation/edit')
          },
          {
            path: 'product-management/master-promo',
            name: 'MasterPromo',
            component: () => import(/* webpackChunkName: "max-purchase-limitation" */ '@/components/pages/dashboard/orion/product-management/master-promo')
          },
          {
            path: 'product-management/master-promo/:id/detail',
            name: 'MasterPromoDetail',
            props: true,
            component: () => import(/* webpackChunkName: "master-promo" */ '@/components/pages/dashboard/orion/product-management/master-promo/detail')
          },
          {
            path: 'product-management/master-promo/:id/edit',
            name: 'MasterPromoEdit',
            props: true,
            component: () => import(/* webpackChunkName: "master-promo" */ '@/components/pages/dashboard/orion/product-management/master-promo/edit')
          },
          {
            path: 'product-management/master-promo/import',
            name: 'MasterPromoImport',
            component: () => import(/* webpackChunkName: "master-promo-import" */ '@/components/pages/dashboard/orion/product-management/master-promo/import')
          }
        ]
      },
      {
        path: 'pandora',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
        props: { type: 'pandora' },
        redirect: getDefaultRedirect('pandora'),
        children: [
          {
            path: 'e-voucher/voucher-report',
            name: 'E-SuperindoVoucherReport',
            component: () => import('@/components/pages/dashboard/pandora/e-voucher/voucher-report')
          },
          {
            path: 'e-voucher/outstanding-voucher-report',
            name: 'E-SuperindoOutstandingVoucherReport',
            component: () => import('@/components/pages/dashboard/pandora/e-voucher/outstanding-voucher-report')
          },
          {
            path: 'e-voucher/sales-and-redeem-voucher-report',
            name: 'E-SuperindoSalesAndRedeemVoucherReport',
            component: () => import('@/components/pages/dashboard/pandora/e-voucher/sales-and-redeem-voucher-report')
          },
          {
            path: 'e-voucher/recap-voucher-report',
            name: 'RecapE-SuperindoVoucherReport',
            component: () => import('@/components/pages/dashboard/pandora/e-voucher/recap-voucher-report')
          },
          {
            path: 'e-voucher/voucher-usage-report',
            name: 'E-SuperindoVoucherUsageReport',
            component: () => import('@/components/pages/dashboard/pandora/e-voucher/voucher-usage-report')
          },
          {
            path: 'e-voucher/voucher-selling-report',
            name: 'E-SuperindoVoucherSellingReport',
            component: () => import('@/components/pages/dashboard/pandora/e-voucher/voucher-selling-report')
          },
          {
            path: 'sales-dashboard',
            name: 'Dashboard',
            component: () => import('@/components/pages/dashboard/pandora/dashboard')
          },
          {
            path: 'sales-dashboard/sales-member/:detailType',
            name: 'DashboardSalesMember',
            component: () => import('@/components/pages/dashboard/pandora/dashboard/sales-member/detail')
          },
          {
            path: 'sales-dashboard/member-transaction/:detailType',
            name: 'DashboardMemberTransaction',
            component: () => import('@/components/pages/dashboard/pandora/dashboard/member-transaction/detail')
          },
          {
            path: 'sales-dashboard/apc-member/:detailType',
            name: 'DashboardApcMember',
            component: () => import('@/components/pages/dashboard/pandora/dashboard/apc-member/detail')
          },
          {
            path: 'sales-dashboard/average-transaction/:detailType',
            name: 'DashboardAverageTransaction',
            component: () => import('@/components/pages/dashboard/pandora/dashboard/average-transaction/detail')
          },
          {
            path: 'sales-dashboard/summary-sales/:detailType',
            name: 'DashboardSalesReport',
            component: () => import('@/components/pages/dashboard/pandora/dashboard/summary-sales/detail')
          },
          {
            path: 'customer-analytics',
            name: 'CustomerAnalytics',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics')
          },
          {
            path: 'customer-analytics/apc-per-customer/:typeDetail',
            name: 'APCPerCustomer',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics/stack-bar-chart/apc-per-customer')
          },
          {
            path: 'customer-analytics/ipc-per-customer/:typeDetail',
            name: 'IPCPerCustomer',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics/stack-bar-chart/ipc-per-customer')
          },
          {
            path: 'customer-analytics/qpc-per-customer/:typeDetail',
            name: 'QPCPerCustomer',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics/stack-bar-chart/qpc-per-customer')
          },
          {
            path: 'customer-analytics/apc-per-transaction/:typeDetail',
            name: 'APCPerTransaction',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics/stack-bar-chart/apc-per-transaction')
          },
          {
            path: 'customer-analytics/ipc-per-transaction/:typeDetail',
            name: 'IPCPerTransaction',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics/stack-bar-chart/ipc-per-transaction')
          },
          {
            path: 'customer-analytics/qpc-per-transaction/:typeDetail',
            name: 'QPCPerTransaction',
            component: () => import('@/components/pages/dashboard/pandora/customer-analytics/stack-bar-chart/qpc-per-transaction')
          },
          {
            path: 'sales-analytics',
            name: 'SalesAnalytics',
            component: () => import('@/components/pages/dashboard/pandora/sales-analytics')
          },
          {
            path: 'sales-analytics/top-sales-category/:detailType',
            name: 'SalesAnalyticsTopCategory',
            component: () => import('@/components/pages/dashboard/pandora/sales-analytics/top-sales-category/detail')
          },
          {
            path: 'sales-analytics/top-sales-plu/:detailType',
            name: 'SalesAnalyticsTopPLU',
            component: () => import('@/components/pages/dashboard/pandora/sales-analytics/top-sales-plu/detail')
          },
          {
            path: 'planogram-analytics',
            name: 'PlanogramAnalytics',
            component: () => import('@/components/pages/dashboard/pandora/planogram-analytics')
          },
          {
            path: 'planogram-analytics/planogram-impressions/:typeDetail',
            name: 'PlanogramImpressionDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/planogram-impressions-detail')
          },
          {
            path: 'planogram-analytics/top-impressions/:typeDetail',
            name: 'TopImpressionDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/TopImpression')
          },
          {
            path: 'planogram-analytics/top-clicks/:typeDetail',
            name: 'TopClickDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/TopClick')
          },
          {
            path: 'planogram-analytics/top-carts/:typeDetail',
            name: 'TopCartDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/TopCart')
          },
          {
            path: 'planogram-analytics/top-sales/:typeDetail',
            name: 'TopSalesDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/TopSales')
          },
          {
            path: 'planogram-analytics/bottom-impressions/:typeDetail',
            name: 'BottomImpressionDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/BottomImpression')
          },
          {
            path: 'planogram-analytics/bottom-clicks/:typeDetail',
            name: 'BottomClickDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/BottomClick')
          },
          {
            path: 'planogram-analytics/bottom-carts/:typeDetail',
            name: 'BottomCartDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/BottomCart')
          },
          {
            path: 'planogram-analytics/bottom-sales/:typeDetail',
            name: 'BottomSalesDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram/detail/BottomSales')
          },
          {
            path: 'planogram-analytics/top-impressions-item/:typeDetail',
            name: 'TopImpressionItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/TopImpressionItems')
          },
          {
            path: 'planogram-analytics/top-clicks-item/:typeDetail',
            name: 'TopClickItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/TopClickItems')
          },
          {
            path: 'planogram-analytics/top-carts-item/:typeDetail',
            name: 'TopCartItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/TopCartItems')
          },
          {
            path: 'planogram-analytics/top-sales-item/:typeDetail',
            name: 'TopSalesItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/TopSalesItems')
          },
          {
            path: 'planogram-analytics/bottom-impressions-item/:typeDetail',
            name: 'BottomImpressionItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/BottomImpressionItems')
          },
          {
            path: 'planogram-analytics/bottom-clicks-item/:typeDetail',
            name: 'BottomClickItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/BottomClickItems')
          },
          {
            path: 'planogram-analytics/bottom-carts-item/:typeDetail',
            name: 'BottomCartItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/BottomCartItems')
          },
          {
            path: 'planogram-analytics/bottom-sales-item/:typeDetail',
            name: 'BottomSalesItemDetails',
            component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/planogram-analytics/stack-bar-chart/by-planogram-items/detail/BottomSalesItems')
          },
          {
            path: 'overview',
            name: 'Overview',
            component: () => import(/* webpackChunkName: "overview" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews-2/index')
          },
          // {
          //   path: 'overview',
          //   name: 'Overview',
          //   component: () => import(/* webpackChunkName: "overview" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/index')
          // },
          // {
          //   path: 'overview/total-details/:typeDetail',
          //   name: 'OverviewsTotalDetails',
          //   component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/total-section/detail')
          // },
          // {
          //   path: 'overview/download-details/:typeDetail',
          //   name: 'OverviewsDownloadDetails',
          //   component: () => import(/* webpackChunkName: "overviews-download-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/downloads/detail')
          // },
          // {
          //   path: 'overview/all-active-users-details/:typeDetail',
          //   name: 'OverviewsAllActiveUsersDetails',
          //   component: () => import(/* webpackChunkName: "overviews-all-active-users-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/all-active-users/detail')
          // },
          // {
          //   path: 'overview/new-open-details/:typeDetail',
          //   name: 'OverviewsNewOpenDetails',
          //   component: () => import(/* webpackChunkName: "overviews-new-open-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/new-opens/detail')
          // },
          // {
          //   path: 'overview/visiting-users-details/:typeDetail',
          //   name: 'OverviewsVisitingUsersDetails',
          //   component: () => import(/* webpackChunkName: "overviews-visiting-users-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/visiting-users/detail')
          // },
          // {
          //   path: 'overview/registrations-details/:typeDetail',
          //   name: 'OverviewsRegistrationDetails',
          //   component: () => import(/* webpackChunkName: "overviews-registrations-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/registrations/detail')
          // },
          // {
          //   path: 'overview/all-engaged-users-details/:typeDetail',
          //   name: 'OverviewsAllEngagedUsersDetails',
          //   component: () => import(/* webpackChunkName: "overviews-all-engaged-users-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/all-engaged-users/detail')
          // },
          // {
          //   path: 'overview/overall-unique-transaction-details/:typeDetail',
          //   name: 'OverviewsOverallUniqueTransactionDetails',
          //   component: () => import(/* webpackChunkName: "overviews-overall-unique-transaction-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/overviews/overal-unique-transaction/detail')
          // },
          {
            path: 'customers',
            name: 'Customers',
            component: () => import(/* webpackChunkName: "customers" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/index')
          },
          {
            path: 'customers/total-details/:typeDetail',
            name: 'CustomersTotalDetails',
            component: () => import(/* webpackChunkName: "customers-total-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/online-offline/detail')
          },
          {
            path: 'customers/downloads-newdevices-registrations-details/:typeDetail',
            name: 'CustomersDownloadNewDevicesRegisrations',
            component: () => import(/* webpackChunkName: "customers-downloads-newdevices-registrations-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/downloads-newdevices-registrations/detail')
          },
          {
            path: 'customers/active-visiting-engaged-users-details/:typeDetail',
            name: 'CustomersActiveVisitingEngagedUsersDetails',
            component: () => import(/* webpackChunkName: "customers-active-visiting-engaged-users-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/active-visiting-engaged-users/detail')
          },
          {
            path: 'customers/active-users-demographics-details/:typeDetail',
            name: 'CustomersActiveUsersDemographics',
            component: () => import(/* webpackChunkName: "customers-active-users-demographics-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/active-users-demographics/detail')
          },
          {
            path: 'customers/active-users-gender-trends-details/:typeDetail',
            name: 'CustomersActiveUsersGenderTrendsDetails',
            component: () => import(/* webpackChunkName: "customers-active-users-gender-trends-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/active-users-gender-trends/detail')
          },
          {
            path: 'customers/device-type-details/:typeDetail',
            name: 'CustomersDeviceTypeDetails',
            component: () => import(/* webpackChunkName: "customers-device-type-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/device-type/detail')
          },
          {
            path: 'customers/device-type-trend-details/:typeDetail',
            name: 'CustomersDeviceTypeTrendDetails',
            component: () => import(/* webpackChunkName: "customers-device-type-trend-details-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/device-type-trend/detail')
          },
          {
            path: 'customers/new-user-online/:typeDetail',
            name: 'CustomersNewUserOnlineDetails',
            component: () => import(/* webpackChunkName: "customers-new-user-online-details-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/new-user-online/detail')
          },
          {
            path: 'customers/new-user-offline/:typeDetail',
            name: 'CustomersNewUserOfflineDetails',
            component: () => import(/* webpackChunkName: "customers-new-user-offline-details-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/new-user-offline/detail')
          },
          {
            path: 'customers/existing-user-online/:typeDetail',
            name: 'CustomersExistingUserOnlineDetails',
            component: () => import(/* webpackChunkName: "customers-existing-user-online-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/existing-user-online/detail')
          },
          {
            path: 'customers/existing-user-offline/:typeDetail',
            name: 'CustomersExistingUserOfflineDetails',
            component: () => import(/* webpackChunkName: "customers-existing-user-offline-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/customers/existing-user-offline/detail')
          },
          {
            path: 'marketing-campaign',
            name: 'MarketingCampaign',
            component: () => import(/* webpackChunkName: "marketing-campaign" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/index')
          },
          {
            path: 'marketing-campaign/total-details/:typeDetail',
            name: 'MarketingCampaignTotalDetails',
            component: () => import(/* webpackChunkName: "marketing-campaign-total-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/total-section/detail')
          },
          {
            path: 'marketing-campaign/top-offers-details/:typeDetail',
            name: 'MarketingCampaignTopOffersDetails',
            component: () => import(/* webpackChunkName: "marketing-campaign-top-offers-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/top-campaign-offers/detail')
          },
          {
            path: 'marketing-campaign/top-banners-details/:typeDetail',
            name: 'MarketingCampaignTopBannersDetails',
            component: () => import(/* webpackChunkName: "marketing-campaign-top-banners-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/top-campaign-banners/detail')
          },
          {
            path: 'marketing-campaign/unique-transaction-details/:typeDetail',
            name: 'MarketingCampaignUniqueTransactionDetails',
            component: () => import(/* webpackChunkName: "marketing-campaign-unique-transaction-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/unique-transaction-campaign/detail')
          },
          {
            path: 'marketing-campaign/sales-by-campaign-details/:typeDetail',
            name: 'MarketingCampaignSalesByCampaignDetails',
            component: () => import(/* webpackChunkName: "marketing-campaign-sales-by-campaign-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/sales-by-campaign/detail')
          },
          {
            path: 'marketing-campaign/average-basket-transaction-details/:typeDetail',
            name: 'MarketingCampaignAverageBasketTransDetails',
            component: () => import(/* webpackChunkName: "marketing-campaign-average-basket-transaction-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/marketing-campaign/average-basket-transaction/detail')
          },
          {
            path: 'content',
            name: 'Content',
            component: () => import(/* webpackChunkName: "content" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/index')
          },
          {
            path: 'content/offer-total-details/:typeDetail',
            name: 'ContentOfferTotalDetails',
            component: () => import(/* webpackChunkName: "content-offer-total-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/total-section/detail')
          },
          {
            path: 'content/offer-clicks-redemptions-details/:typeDetail',
            name: 'ContentOfferClicksRedemptionsDetails',
            component: () => import(/* webpackChunkName: "content-offer-clicks-redemptions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/offer-clicks-and-redemptions/detail')
          },
          {
            path: 'content/offer-top-redemptions-details/:typeDetail',
            name: 'ContentTopOfferRedemptionsDetails',
            component: () => import(/* webpackChunkName: "content-offer-top-redemptions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/top-offer-redemptions/detail')
          },
          {
            path: 'content/offer-bottom-redemptions-details/:typeDetail',
            name: 'ContentBottomOfferRedemptionsDetails',
            component: () => import(/* webpackChunkName: "content-offer-bottom-redemptions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/bottom-offer-redemptions/detail')
          },
          {
            path: 'content/offer-top-sales-value-details/:typeDetail',
            name: 'ContentTopOfferSalesDetails',
            component: () => import(/* webpackChunkName: "content-offer-top-sales-value-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/top-offer-sales-value/detail')
          },
          {
            path: 'content/offer-bottom-sales-value-details/:typeDetail',
            name: 'ContentBottomOfferSalesDetails',
            component: () => import(/* webpackChunkName: "content-offer-bottom-sales-value-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/bottom-offer-sales-value/detail')
          },
          {
            path: 'content/offer-top-member-transaction-details/:typeDetail',
            name: 'ContentTopMemberTransactionOfferDetails',
            component: () => import(/* webpackChunkName: "content-offer-transaction-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/top-member-transaction-offer/detail')
          },
          {
            path: 'content/offer-top-member-basket-offer-details/:typeDetail',
            name: 'ContentTopMemberBasketOfferDetails',
            component: () => import(/* webpackChunkName: "content-offer-top-member-basket-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/offers/top-basket-offer/detail')
          },
          {
            path: 'content/banner-total-details/:typeDetail',
            name: 'ContentBannerTotalDetail',
            props: true,
            component: () => import(/* webpackChunkName: "content-banner-total-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/banners/total-section/detail')
          },
          {
            path: 'content/banner-impressions-clicks-details/:typeDetail',
            name: 'ContentBannerImpressionsClicksDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-banner-impressions-clicks-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/banners/banner-impressions-clicks/detail')
          },
          {
            path: 'content/banner-top-impressions-details/:typeDetail',
            name: 'ContentTopBannerImpressionsDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-banner-top-impressions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/banners/top-banners-impressions/detail')
          },
          {
            path: 'content/banner-top-clicks-details/:typeDetail',
            name: 'ContentTopBannerClicksDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-banner-top-clicks-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/banners/top-banners-clicks/detail')
          },
          {
            path: 'content/banner-bottom-clicks-details/:typeDetail',
            name: 'ContentBottomBannerClicksDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-banner-bottom-clicks-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/banners/bottom-banners-clicks/detail')
          },
          {
            path: 'content/banner-bottom-impressions-details/:typeDetail',
            name: 'ContentBottomBannerImpressionsDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-banner-bottom-redemptions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/banners/bottom-banners-impressions/detail')
          },
          {
            path: 'content/messages-total-details/:typeDetail',
            name: 'ContentMessagesTotalDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-total-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/total-section/detail')
          },
          {
            path: 'content/messages-sent-impressions-clicks-details/:typeDetail',
            name: 'ContentMessagesSentImpressionsClicksDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-sent-impressions-clicks-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/message-sent-impressions-clicks/detail')
          },
          {
            path: 'content/messages-click-rate-details/:typeDetail',
            name: 'ContentMessagesClickRateDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-click-rate-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/message-click-rate/detail')
          },
          {
            path: 'content/messages-top-messages-impressions-details/:typeDetail',
            name: 'ContentMessagesTopMessagesImpressionsDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-top-messages-impressions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/top-messages-impressions/detail')
          },
          {
            path: 'content/messages-top-messages-clicks-details/:typeDetail',
            name: 'ContentMessagesTopMessagesClicksDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-top-messages-clicks-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/top-messages-clicks/detail')
          },
          {
            path: 'content/messages-bottom-messages-impressions-details/:typeDetail',
            name: 'ContentMessagesBottomMessagesImpressionsDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-bottom-messages-impressions-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/bottom-messages-impressions/detail')
          },
          {
            path: 'content/messages-bottom-messages-clicks-details/:typeDetail',
            name: 'ContentMessagesBottomMessagesClicksDetails',
            props: true,
            component: () => import(/* webpackChunkName: "content-messages-bottom-messages-clicks-details" */ '@/components/pages/dashboard/pandora/dashboard/analytic/content/messages/bottom-messages-clicks/detail')
          },
          {
            path: 'help',
            name: 'Help',
            component: () => import(/* webpackChunkName: "help" */ '@/components/pages/dashboard/pandora/dashboard/analytic/help')
          },
          {
            path: 'sales',
            name: 'Sales',
            component: () => import(/* webpackChunkName: "sales" */ '@/components/pages/dashboard/pandora/dashboard/analytic/sales')
          },
          {
            path: 'sales/detail/:typeDetail',
            name: 'SalesDetail',
            props: true,
            component: () => import(/* webpackChunkName: "sales-detail" */ '@/components/pages/dashboard/pandora/dashboard/analytic/sales/sales-summary/detail')
          },
          {
            path: 'sales/detail-top-store/:typeDetail',
            name: 'SalesTopStoreDetail',
            props: true,
            component: () => import(/* webpackChunkName: "sales-top-store-detail" */ '@/components/pages/dashboard/pandora/dashboard/analytic/sales/sales-top-bottom/detail/top-rank-store')
          },
          {
            path: 'sales/detail-bottom-store/:typeDetail',
            name: 'SalesBottomStoreDetail',
            props: true,
            component: () => import(/* webpackChunkName: "sales-bottom-storedetail" */ '@/components/pages/dashboard/pandora/dashboard/analytic/sales/sales-top-bottom/detail/bottom-rank-store')
          },
          {
            path: 'report/all-transactions/',
            name: 'ReportAllTransactions',
            props: true,
            component: () => import(/* webpackChunkName: "report-all-transactions" */ '@/components/pages/dashboard/pandora/reports/all-transactions')
          },
          {
            path: 'report/all-transactions/:id/detail',
            name: 'ReportAllTransactionDetail',
            props: true,
            component: () => import(/* webpackChunkName: "report-all-transaction-detail" */ '@/components/pages/dashboard/pandora/reports/all-transactions/detail')
          },
          {
            path: 'report/all-transactions/:id/preview',
            name: 'ReportAllTransactionPreview',
            props: true,
            component: () => import(/* webpackChunkName: "order-management-preview" */ '@/components/pages/dashboard/pandora/reports/all-transactions/preview')
          },
          {
            path: 'report/alp-report/',
            name: 'ReportALP',
            props: true,
            component: () => import(/* webpackChunkName: "report-alp-report" */ '@/components/pages/dashboard/pandora/reports/alp-report')
          },
          {
            path: 'report/alp-report/:transactionType/:id/detail',
            name: 'ReportALPDetail',
            props: true,
            component: () => import(/* webpackChunkName: "report-alp-report-detail" */ '@/components/pages/dashboard/pandora/reports/alp-report/detail')
          },
          {
            path: 'report/monthly-recap/',
            name: 'MonthlyRecap',
            props: true,
            component: () => import(/* webpackChunkName: "report-alp-report" */ '@/components/pages/dashboard/pandora/reports/monthly-recap')
          },
          {
            path: 'free-gift-report',
            name: 'FreeGiftReport',
            component: () => import(/* webpackChunkName: "free-gift-report" */ '@/components/pages/dashboard/pandora/free-gift-report')
          },
          {
            path: 'free-gift-report/:id/detail',
            name: 'FreeGiftReportDetail',
            props: true,
            component: () => import(/* webpackChunkName: "free-gift-report-detail" */ '@/components/pages/dashboard/pandora/free-gift-report/detail')
          },
          {
            path: 'promo-report',
            name: 'PromoReport',
            component: () => import(/* webpackChunkName: "free-gift-report" */ '@/components/pages/dashboard/pandora/promo-report')
          },
          {
            path: 'report-helpdesk/report/',
            name: 'reportHelpdesk',
            props: true,
            component: () => import(/* webpackChunkName: "report-helpdesk" */ '@/components/pages/dashboard/pandora/report-helpdesk/report')
          },
          {
            path: 'report-helpdesk/summary/',
            name: 'summaryHelpdesk',
            props: true,
            component: () => import(/* webpackChunkName: "summary-helpdesk" */ '@/components/pages/dashboard/pandora/report-helpdesk/summary')
          }
        ]
      },
      {
        path: 'pandora/analytic/preview',
        name: 'PreviewAnalytic',
        component: () => import(/* webpackChunkName: "preview" */ '@/components/globals/preview-analytic')
      },
      {
        path: 'pandora/preview',
        name: 'Preview',
        component: () => import(/* webpackChunkName: "preview" */ '@/components/globals/preview')
      },
      {
        path: 'heimdal',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
        props: { type: 'heimdal' },
        redirect: getDefaultRedirect('heimdal'),
        children: [
          {
            path: 'consumer-login-log',
            name: 'ConsumerLoginLog',
            component: () => import('@/components/pages/dashboard/heimdal/consumer-login-log')
          },
          {
            path: 'consumer-login-log/:id/consumer',
            name: 'ConsumerLoginConsumerDetail',
            component: () => import('@/components/pages/dashboard/heimdal/consumer-login-log/detail'),
            props: true
          },
          {
            path: 'voucher-used-log',
            name: 'VoucherUsedLog',
            component: () => import('@/components/pages/dashboard/heimdal/voucher-used-log')
          },
          {
            path: 'voucher-used-log/:id/detail-consumer',
            name: 'VoucherUsedDetailConsumer',
            component: () => import('@/components/pages/dashboard/heimdal/voucher-used-log/detail-consumer'),
            props: true
          },
          {
            path: 'voucher-used-log/:id/detail-order',
            name: 'VoucherUsedDetailOrder',
            component: () => import('@/components/pages/dashboard/heimdal/voucher-used-log/detail-order'),
            props: true
          },
          {
            path: 'voucher-used-log/:id/detail-voucher',
            name: 'VoucherUsedDetail',
            component: () => import('@/components/pages/dashboard/heimdal/voucher-used-log/detail-voucher'),
            props: true
          },
          {
            path: 'digital-stamp-inject',
            name: 'DigitalStickerInject',
            component: () => import('@/components/pages/dashboard/heimdal/digital-stamp-inject')
          },
          {
            path: 'digital-stamp-inject/import',
            name: 'DigitalStickerInjectImport',
            component: () => import(/* webpackChunkName: "digital-stamp-inject" */ '@/components/pages/dashboard/heimdal/digital-stamp-inject/upload'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/components/pages/NotFound'),
    meta: {
      public: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(''),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = isLogin()
  
  // let userRole;

  // if(loggedIn){
  //   userRole = getUserRole()
  // }

  // to.matched.map(record => {
  //   if (record.meta.roles === undefined) {
  //     next()
  //   }else{
  //     if(record.meta.roles.some(item => item == userRole)){
  //       next()
  //     }else{
  //       next("/admin/unauthorized")
  //     }
  //   }
  // })

  if (!isPublic && !loggedIn) {
    if (to.fullPath == '/admin/role/inactive') {
      return next({
        path: '/login'
      })
    }
    return next({
      path: '/login',
      query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
    })
  }
  
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/admin')
  }
  // Check if access not authorize 
  if (loggedIn && (!isAuthorizePage(to.fullPath)) && from.path == '/login') {
    return next('/admin')
  }
  
  next()
})

export default router
