import { createStore } from "vuex"
import mobile from './modules/mobile/reset-password-verification'
import auth from './modules/auth'
import consumer from './modules/consumer/consumer'
import faq from './modules/consumer/faq'
import helpCenter from './modules/consumer/help-center'
import helpdesk from './modules/consumer/helpdesk'
import ticketCategoryHelpdesk from './modules/master-data/ticket-category-helpdesk'
import masterMappingEmail from './modules/master-data/master-mapping-email'
import templateHC from './modules/consumer/template-hc'
import chatbot from './modules/consumer/chatbot'
import chatlive from './modules/consumer/chatlive'
import approvalMessage from './modules/consumer/approval-message'
import notifOffer from './modules/campaign/notification-offer'
import campaign from './modules/campaign/campaigns'
import offer from './modules/campaign/offers'
import banner from './modules/campaign/banners'
import pushMessage from './modules/campaign/push-messages'
import role from './modules/user-management/role'
import user from './modules/user-management/user'
import logHistory from './modules/user-management/log-history'
import consumerTag from './modules/master-data/consumer-tag'
import groupStore from './modules/master-data/group-store'
import store from './modules/master-data/store'
import product from './modules/master-data/product'
import privacyPolicy from './modules/master-data/privacy-policy'
import settingApp from './modules/master-data/setting-app'
import termCondition from './modules/master-data/term-condition'
import stamp from './modules/digital-stamp/stamp'
import reward from './modules/digital-stamp/reward'
import howtoRedeem from './modules/digital-stamp/how_to_redeem'
import report from './modules/digital-stamp/report'
import roleManagement from './modules/main-page/user-management/role'
import userManagement from './modules/main-page/user-management/user'
import productManagement from './modules/product-management/product'
import categoryManagement from './modules/category-management'
import promoManagement from './modules/promo-management/promo'
import planogramManagement from './modules/planogram-management/planogram'
import threePLManagement from './modules/3pl-setting/three_pl'
import associateLoyaltyProgram from './modules/associate-loyalty-program/index'
import memberManagement from './modules/member-management'
import subCategory from './modules/category-management/index'
import storeManagement from './modules/store-management/index'
import generalSetting from './modules/general-setting'
import appVersion from './modules/app-version'
import consumerHistory from './modules/digital-stamp/consumer'
import orderManagement from './modules/order-management'
import customerAnalytics from './modules/pandora/customer'
import eVoucher from './modules/pandora/e-voucher'
import configEVoucher from './modules/method-of-payment/e-voucher'
import salesAnalytics from './modules/pandora/sales-analytics'
import salesTransaction from './modules/pandora/sales-transaction'
import overview from './modules/pandora/analytic/overview-2'
import customer from './modules/pandora/analytic/customers'
import marketing from './modules/pandora/analytic/marketing'
import contentBanner from './modules/pandora/analytic/content/banner'
import contentMessage from './modules/pandora/analytic/content/message'
import contentOffer from './modules/pandora/analytic/content/offer'
import sales from './modules/pandora/analytic/sales'
import alpReport from './modules/pandora/report/alp'
import global from './modules/global'
import form from './modules/polling-survey/form'
import response from './modules/polling-survey/response'
import maxPurchase from './modules/max-purchase-limitation'
import couponLog from './modules/digital-coupon/coupon-log'
import couponSetting from './modules/digital-coupon/coupon-setting'
import raffle from './modules/digital-coupon/raffle'
import downloadList from './modules/download-list'
import consumerLoginLog from './modules/consumer-login-log/'
import voucherUsedLog from './modules/voucher-used-log/'
import digitalStampInject from './modules/digital-stamp-inject'
import freeGift from './modules/free-gift-promotion'
import freeGiftReport from './modules/pandora/free-gift-report'
import promoReport from './modules/pandora/promo-report'
import planogramAnalytics from './modules/pandora/planogram-analytics'
import masterPromo from './modules/master-promo'
import helpdeskReport from './modules/pandora/helpdesk/report'
import topUpBills from './modules/topup-bills'
import digitalProductManagement from './modules/digital-product-management'
import orderReport from './modules/pandora/report/order'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    mobile,
    auth,
    faq,
    consumer,
    campaign,
    offer,
    role,
    user,
    logHistory,
    groupStore,
    consumerTag,
    store,
    product,
    privacyPolicy,
    termCondition,
    banner,
    helpCenter,
    helpdesk,
    ticketCategoryHelpdesk,
    masterMappingEmail,
    templateHC,
    chatbot,
    chatlive,
    approvalMessage,
    notifOffer,
    pushMessage,
    settingApp,
    stamp,
    reward,
    howtoRedeem,
    report,
    roleManagement,
    userManagement,
    storeManagement,
    subCategory,
    categoryManagement,
    memberManagement,
    productManagement,
    generalSetting,
    appVersion,
    promoManagement,
    planogramManagement,
    threePLManagement,
    associateLoyaltyProgram,
    consumerHistory,
    orderManagement,
    customerAnalytics,
    eVoucher,
    configEVoucher,
    salesAnalytics,
    salesTransaction,
    overview,
    customer,
    marketing,
    contentBanner,
    contentMessage,
    contentOffer,
    sales,
    alpReport,
    global,
    form,
    response,
    maxPurchase,
    couponLog,
    couponSetting,
    raffle,
    downloadList,
    consumerLoginLog,
    voucherUsedLog,
    digitalStampInject,
    freeGift,
    freeGiftReport,
    promoReport,
    orderReport,
    masterPromo,
    helpdeskReport,
    planogramAnalytics,
    topUpBills,
    digitalProductManagement
  }
})
